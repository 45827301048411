<template><div><h1 id="known-issues" tabindex="-1"><a class="header-anchor" href="#known-issues"><span>Known Issues</span></a></h1>
<h2 id="operating-systems" tabindex="-1"><a class="header-anchor" href="#operating-systems"><span>Operating Systems</span></a></h2>
<h3 id="windows" tabindex="-1"><a class="header-anchor" href="#windows"><span>Windows</span></a></h3>
<ul>
<li>Some Chromebooks have no touchpad support in the installer.</li>
<li>No fingerprint reader support.</li>
<li>No TPM 2.0 support.</li>
<li>No IPU camera support.</li>
</ul>
<h3 id="linux" tabindex="-1"><a class="header-anchor" href="#linux"><span>Linux</span></a></h3>
<ul>
<li>Broken USB-C on TigerLake and AlderLake. See Post Install -&gt; Linux for fix.</li>
<li>No fingerprint reader support.</li>
<li>Debian 12 (Bookworm) needs a custom kernel.</li>
</ul>
<h3 id="macos" tabindex="-1"><a class="header-anchor" href="#macos"><span>macOS</span></a></h3>
<ul>
<li>Broken DRM.</li>
<li>No audio.</li>
<li>No PCIe SD card reader support.</li>
<li>No fingerprint reader support.</li>
<li>Continuity features (AirDrop, Sidecar, etc).</li>
</ul>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>For issues relating to hardware/firmware, see <RouteLink to="/docs/firmware/known-issues.html">this page</RouteLink>.</p>
</div>
</div></template>


